.columnDesktop{
	display: flex;
	flex-direction: row ;	
}
.allCards{
	width: 350px;
	border: red 2px solid;
}
.inlineDesktop{
	display: flex;
	flex-direction: column ;
	width: 100%;
	justify-content: space-evenly;		
}
.gemCardGreen {
	width: 90px;
	height: 121px;
	border: solid 2px #c6b76d;
	padding-left: 2px;
	padding-right: 10px;
	align-items: center;
	flex-direction: column ;
	background-color: #2e293c;
	color: green;
	cursor: pointer;
}
.gemCardTeal {
	width: 90px;
	height: 121px;
	border: solid 2px #c6b76d;
	padding-left: 2px;
	padding-right: 10px;
	align-items: center;
	flex-direction: column ;
	background-color: #2e293c;
	color: #3cd;
	cursor: pointer;
}	
.gemCardBlue {
	width: 90px;
	height: 121px;
	border: solid 2px #c6b76d;
	padding-left: 2px;
	padding-right: 10px;
	align-items: center;
	flex-direction: column ;
	background-color: #2e293c;
	color: #44f;
	cursor: pointer;
}
.gemCardPurple {
	width: 90px;
	height: 121px;
	border: solid 2px #c6b76d;
	padding-left: 2px;
	padding-right: 10px;
	align-items: center;
	flex-direction: column ;
	background-color: #2e293c;
	color: #c3c;
	cursor: pointer;
}	
.gemCardRed {
	width: 90px;
	height: 121px;
	border: solid 2px #c6b76d;
	padding-left: 2px;
	padding-right: 10px;
	align-items: center;
	flex-direction: column ;
	background-color: #2e293c;
	color: #d22;
	cursor: pointer;
}

.greenGem {
	width: 60px;
	height: 60px;
	background: transparent url(greenGem.png);
	background-size: 100%;
	
}
.tealGem {
	width: 60px;
	height: 60px;
	background: transparent url(tealGem.png);
	background-size: 100%;
	margin-left: 2px;
}	
.blueGem {
	width: 60px;
	height: 60px;
	background: transparent url(blueGem.png);
	background-size: 100%;
	margin-left: 2px;
}
.purpleGem {
	width: 60px;
	height: 60px;
	background: transparent url(purpleGem.png);
	background-size: 100%;
	margin-left: 2px;
}	
.redGem {
	width: 60px;
	height: 60px;
	background: transparent url(redGem.png);
	background-size: 100%;
	margin-left: 2px;
}
.greenTear {
	width: 60px;
	height: 60px;
	background: transparent url(greenTear.png);
	background-size: 100%;
}
.tealTear {
	width: 60px;
	height: 60px;
	background: transparent url(tealTear.png);
	background-size: 100%;
	margin-left: 2px;
}	
.blueTear {
	width: 60px;
	height: 60px;
	background: transparent url(blueTear.png);
	background-size: 100%;
	margin-left: 2px;
}
.purpleTear {
	width: 60px;
	height: 60px;
	background: transparent url(purpleTear.png);
	background-size: 100%;
	margin-left: 2px;
}
.redTear {
	width: 60px;
	height: 60px;
	background: transparent url(redTear.png);
	background-size: 100%;
	margin-left: 2px;
}	
.greenHeart {
	width: 60px;
	height: 60px;
	background: transparent url(greenHeart.png);
	background-size: 100%;
	margin-left: 2px;		
}
.tealHeart {
	width: 60px;
	height: 60px;
	background: transparent url(tealHeart.png);
	background-size: 100%;
	margin-left: 2px;
}
.blueHeart {
	width: 60px;
	height: 60px;
	background: transparent url(blueHeart.png);
	background-size: 100%;
	margin-left: 2px;
}
.purpleHeart {
	width: 60px;
	height: 60px;
	background: transparent url(purpleHeart.png);
	background-size: 100%;
	margin-left: 2px;
}	
.redHeart {
	width: 60px;
	height: 60px;
	background: transparent url(redHeart.png);
	background-size: 100%;
	margin-left: 2px;
}
.greenDiamond {
	width: 60px;
	height: 60px;
	background: transparent url(greenDiamond.png);
	background-size: 100%;
	margin-left: 2px;		
}
.tealDiamond {
	width: 60px;
	height: 60px;
	background: transparent url(tealDiamond.png);
	background-size: 100%;
	margin-left: 2px;
}
.blueDiamond {
	width: 60px;
	height: 60px;
	background: transparent url(blueDiamond.png);
	background-size: 100%;
	margin-left: 2px;
}
.purpleDiamond {
	width: 60px;
	height: 60px;
	background: transparent url(purpleDiamond.png);
	background-size: 100%;
	margin-left: 2px;
}	
.redDiamond {
	width: 60px;
	height: 60px;
	background: transparent url(redDiamond.png);
	background-size: 100%;
	margin-left: 2px;
}
.roundGems{
	width: 304px;
	height: 277px;
	background: transparent url(gemIcons/roundTrade.png);
	background-size: 100%;	
}
.textValuesGem h4{
	margin-top: 10px;
	margin-bottom: 12px;
	color: black;
	text-align: left;
}
.dropGems{
	width: 310px;
	height: 238px;
	background: transparent url(gemIcons/teardropTrade.png);
	background-size: 100%;	
}
.textValuesDrop h4{
	margin-top: 10px;
	margin-bottom: 12px;
	color: black;
	text-align: left;
	height: 20px;
}
.dropValues h3{
	margin-top: 4px;
	margin-bottom: 6px;
	color: black;
}
.dropValues {
border: solid 1px black;
	color: black;
}
.heartGems{
	width: 310px;
	height: 225px;
	background: transparent url(gemIcons/heartTrade.png);
	background-size: 100%;	
}
.textValuesHeart h4{
	margin-top: 10px;
	margin-bottom: 12px;
	color: black;
	text-align: left;
}
.diamondGems{
	width: 310px;
	height: 170px;
	background: transparent url(gemIcons/diamondTrade.png);
	background-size: 100%;	
}
.textValuesDiamond h4{
	margin-top: 12px;
	margin-bottom: 15px;
	color: black;
	text-align: left;
	height: 21px;
}	
.textValues{
	width: 360px;
	height: 640px;
	text-align: center;
	background-size: 100%;
}

.gemValues h3{
	margin-top: 4px;
	margin-bottom: 10px;
	color: black;
}
.gemValues {
	border: solid 1px black;
	color: black;
}
.tradeValue h3 {
	margin-top: 20px;
	color: black;
}
.theTrade{
	border: 2px ridge yellow;	
	padding: 5px;	
	border-radius: 20px;	
	background-color: #442277;	
	height: 400px;		
}
.notApproved {
	color: tomato;
}
.gemButtons{
	width: 120px;
	border: 2px ridge yellow;
	padding-left: 10px;
	background-color: #442277;
	border-radius: 20px;
	height: 180px;


}
.gemSpacer{
	width: 40px;
	height: 40px
}
.gemSpacerMobile{
	width: 40px;
	height: 10px
}
h4 h3 h2 {
	color: black;
}
.center {
	display: flex;
	justify-content: center;
}
	/* desktop screen */
@media only screen and (min-width: 1150px){
	.gemSpacerMobile{
	width: 40px;
	height: 20px
}
	.columnDesktop{
		display: flex;
		flex-direction: column ;	
	}
		.allCards{
		width: 550px;
		border: red 2px solid;
	}
	.inlineDesktop{
		display: flex;
		flex-direction: row ;
		width: 100%;
		height: 100%;
		justify-content: space-evenly;
	}
	.gemCardGreen {
		width: 110px;
		height: 160px;
		border: solid 2px #c6b76d;
		padding-left: 12px;
		padding-right: 10px;
		align-items: center;
		flex-direction: column ;
		background-color: #2e293c;
		color: green;
		cursor: pointer;
	}
	.gemCardTeal {
		width: 110px;
		height: 160px;
		border: solid 2px #c6b76d;
		padding-left: 12px;
		padding-right: 10px;
		align-items: center;
		flex-direction: column ;
		background-color: #2e293c;
		color: #3cd;
		cursor: pointer;
	}	
	.gemCardBlue {
		width: 110px;
		height: 160px;
		border: solid 2px #c6b76d;
		padding-left: 12px;
		padding-right: 10px;
		align-items: center;
		flex-direction: column ;
		background-color: #2e293c;
		color: #44f;
		cursor: pointer;
	}
	.gemCardPurple {
		width: 110px;
		height: 160px;
		border: solid 2px #c6b76d;
		padding-left: 12px;
		padding-right: 10px;
		align-items: center;
		flex-direction: column ;
		background-color: #2e293c;
		color: #c3c;
		cursor: pointer;
	}	
	.gemCardRed {
		width: 110px;
		height: 160px;
		border: solid 2px #c6b76d;
		padding-left: 12px;
		padding-right: 10px;
		align-items: center;
		flex-direction: column ;
		background-color: #2e293c;
		color: #d22;
		cursor: pointer;
	}
	.gemButtons{
		width: 160px;
		border: 2px ridge yellow;
		padding-left: 10px;
		background-color: #442277;
		border-radius: 20px;
		height: 210px;
		margin: 0 10px 0 10px;
	}
	.theTrade{
		border: 2px ridge yellow;	
		padding: 5px;	
		border-radius: 20px;	
		background-color: #442277;	
		height: 620px;		
	}
	.roundGems{
	width: 704px;
	height: 640px;
	}
	.gemValues h3{
		margin-top: 20px;
		margin-bottom: 41px;
	}
	.textValuesGem h4{
		margin-top: 20px;
		margin-bottom: 42px;
	}
	.dropGems{
		width: 704px;
		height: 542px;
	}
	.textValuesDrop h4{
		margin-top: 1px;
		margin-bottom: 15px;
		height: 40px;
	}
	.dropValues h3{
		margin-top: 20px;
		margin-bottom: 30px;
		color: black;
	}
	.heartGems{
		width: 704px;
		height: 515px;	
	}
	.textValuesHeart h4{
		margin-top: 10px;
		margin-bottom: 25px;
	}
	.diamondGems{
		width: 704px;
		height: 386px;
	}
	.textValuesDiamond h4{
		margin-top: 12px;
		margin-bottom: 15px;
		height: 51px;
	}
	.greenGem {
		width: 80px;
		height: 80px;
		background: transparent url(greenGem.png);
		background-size: 100%;
		
	}
	.tealGem {
		width: 80px;
		height: 80px;
		background: transparent url(tealGem.png);
		background-size: 100%;
		margin-left: 2px;
	}	
	.blueGem {
		width: 80px;
		height: 80px;
		background: transparent url(blueGem.png);
		background-size: 100%;
		margin-left: 2px;
	}
	.purpleGem {
		width: 80px;
		height: 80px;
		background: transparent url(purpleGem.png);
		background-size: 100%;
		margin-left: 2px;
	}	
	.redGem {
		width: 80px;
		height: 80px;
		background: transparent url(redGem.png);
		background-size: 100%;
		margin-left: 2px;
	}
	.greenTear {
		width: 80px;
		height: 80px;
		background: transparent url(greenTear.png);
		background-size: 100%;
	}
	.tealTear {
		width: 80px;
		height: 80px;
		background: transparent url(tealTear.png);
		background-size: 100%;
		margin-left: 2px;
	}	
	.blueTear {
		width: 80px;
		height: 80px;
		background: transparent url(blueTear.png);
		background-size: 100%;
		margin-left: 2px;
	}
	.purpleTear {
		width: 80px;
		height: 80px;
		background: transparent url(purpleTear.png);
		background-size: 100%;
		margin-left: 2px;
	}
	.redTear {
		width: 80px;
		height: 80px;
		background: transparent url(redTear.png);
		background-size: 100%;
		margin-left: 2px;
	}	
	.greenHeart {
		width: 80px;
		height: 80px;
		background: transparent url(greenHeart.png);
		background-size: 100%;
		margin-left: 2px;		
	}
	.tealHeart {
		width: 80px;
		height: 80px;
		background: transparent url(tealHeart.png);
		background-size: 100%;
		margin-left: 2px;
	}
	.blueHeart {
		width: 80px;
		height: 80px;
		background: transparent url(blueHeart.png);
		background-size: 100%;
		margin-left: 2px;
	}
	.purpleHeart {
		width: 80px;
		height: 80px;
		background: transparent url(purpleHeart.png);
		background-size: 100%;
		margin-left: 2px;
	}	
	.redHeart {
		width: 80px;
		height: 80px;
		background: transparent url(redHeart.png);
		background-size: 100%;
		margin-left: 2px;
	}
	.greenDiamond {
		width: 80px;
		height: 80px;
		background: transparent url(greenDiamond.png);
		background-size: 100%;
		margin-left: 2px;		
	}
	.tealDiamond {
		width: 80px;
		height: 80px;
		background: transparent url(tealDiamond.png);
		background-size: 100%;
		margin-left: 2px;
	}
	.blueDiamond {
		width: 80px;
		height: 80px;
		background: transparent url(blueDiamond.png);
		background-size: 100%;
		margin-left: 2px;
	}
	.purpleDiamond {
		width: 80px;
		height: 80px;
		background: transparent url(purpleDiamond.png);
		background-size: 100%;
		margin-left: 2px;
	}	
	.redDiamond {
		width: 80px;
		height: 80px;
		background: transparent url(redDiamond.png);
		background-size: 100%;
		margin-left: 2px;
	}	
}