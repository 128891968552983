@font-face {
	font-family:Imported;
	src: url(./fonts/Foundation.otf);
}

#root {
  font-family: 'Orbitron', sans serif ;
}
body {
	font-family: 'Orbitron', sans serif ;
}
h3{
	margin: 1px;
}

