.headerContainer{
	display: flex;
	justify-content: center ;
	margin-top: 10px ;	

}
.headerContainerGems{
	display: flex;
	justify-content: center ;
	margin-top: 10px ;
	height: 100% ;	
	overflow-y: scroll;
}

.userContainer{
	background-color: #2e293c;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	flex-direction: column ;
	width: 100% ;
	max-width: 1200px ;
	padding: 10px 10px ;
	height: 100% ;
	align-items: center;
	color: #c6b76d;		
		
}
.userContainerUserRace{
	
}

.userGemContainer{
	height: 130%;
}

.shortUser{
	height: 750px;	
}
.modalContainer{
	
}
.modalPlotContainer{
	height: 920px;
}
.modalHelmContainer{
	display: flex;
	justify-content: center ;
	height: 800px;
}
.alfBorder{
	height: 490px ;
	margin-bottom: 10px;

}
.alfContainer{
	background-color: #664499;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	flex-direction: column ;
	width: 100% ;
	max-width: 1200px ;
	padding: 10px ;
	height: 780px ;
	justify-content: space-between ;
	color: #5588ff;
	margin-top: 10px ;
	
}
.registerContainer{
	margin-top: 50px;
}
.registerName{
	padding: 20px; 
	height: 340px ;
}
.gemContainer{
	padding: 20px; 
	height: 130% ;
}
.gemAlfContainer{
	max-width: 785px ;	
	min-height: 680px ;
	height: 130%;
	align-items: center;

}
.leaderAlfContainer{
	height: 1050px;
	height: 130% ;
}
.buttonContainer{
	background-color: #2e293c;
	border: 2px solid #c6b76d;
	border-radius: 10px;
	width: 100px ;
	padding: 2px;
	height: 30px ;
	align-items: center;
	color: #c6b76d;	
	cursor: pointer;
}
.closeButton{
	background-color: #c22;
}
.revokeButton{	
	background-color: #c22;
	color: #c6b76d;
}

.approveButton{
	background-color: #4c2;
	color: black;
}
.frontPageButton{
	background-color: #2e293c;
	border: 2px solid #c6b76d;
	border-radius: 10px;
	width: 150px ;
	padding: 2px;
	height: 30px ;
	align-items: center;
	color: #c6b76d;	
	cursor: pointer;
}
.buttonNarrow{
	background-color: #2e293c;
	border: 2px solid #c6b76d;
	border-radius: 10px;
	width: 50px ;
	padding: 2px;
	padding-left: 10px;
	height: 28px ;
	margin-top: 5px;
	align-items: center;
	color: #c6b76d;	
	cursor: pointer;
}
.buttonNarrow:active{
	background-color: white;
}	
.enterRaceButtonContainer{
	width: 160px;
}
.frontPage{
	margin-top:400px;
}
.frontPage :hover{
	background-color: #664499;
	}
.frontContainer{
	background-image: url('frontPageMobile1.jpg') ;
	background-size: 100%;
	height:800px;	
	width: 400px;
	align-items: center;
	display: flex;
	flex-direction: column ;
	margin: auto;	
}
.tarImageL{
	float: left;
	width: 20%;
}
.tarImageR{
	float: right;
	width: 20%;
}
span{
	width: 100%;
}
.wordy{
	background-color: #282c34;
	font-family: 'Orbitron', sans serif ;
	margin-top: 1px;
	display: flex;
	justify-content: center;
	text-align: center;
}

.musicIcons{

}

.trackButtons{
	display: flex;
	flex-direction: row;
}
.trackUp{
	cursor: pointer;
	margin-left: 10px;
}

.trackDown{
	cursor: pointer;
	margin-right: 10px;
}

.bothBoxes{
	display: flex;
	flex-direction: column;
	text-align: center;
    background-color: #660099;
    border: 2px solid #c6b76d;
    border-radius: 35px;
	width: 100%;
	text-align: center;
}

.BiggerBox{
	max-width: 900px ;
	border-radius: 35px;
	border: 2px solid #c6b76d;
	width: 97%;
	padding: 5px;
	background-color: #2e293c;
	display: flex;
	flex-direction: column ;
	margin: 5px;
	justify-content: space-between;
	height: 100px;
}

.audio-gif{
	background-image: url(audio2.gif);
	background-size: cover;	
	width:230px;
	border-radius: 35px;
	border: 2px solid #c6b76d;
	width: 97%;
	padding: 5px;
	background-color: #2e293c;
	flex-direction: column ;
	margin: 5px;
}
.audioPause{
	background-image: url(audioPause.png);
	background-size: cover;
	width:230px;
	border-radius: 35px;
	border: 2px solid #c6b76d;
	width: 97%;
	padding: 5px;
	background-color: #2e293c;
	flex-direction: column ;
	margin: 5px;	
}
.audioStop{
	width:230px;
	border-radius: 35px;
	border: 2px solid #c6b76d;
	width: 97%;
	padding: 5px;
	background-color: #2e293c;
	flex-direction: column ;
	margin: 5px;	
}
h4, h5{
	font-size: 12px;
}

h3{
	font-size: 14px;
	
}

h5, h4, h3{
	text-align: center;
}
h2{
	margin: 5px;
	text-align: center;
}
.inline{
	display: flex;
	flex-direction: row ;
	width: 100%;
	justify-content: space-evenly;
}
.inlineCol{
	display: flex;
	flex-direction: column; 
}
.inlineTight{
	display: flex;
	flex-direction: row ;
	width: 100%;
}
.inlineText{
	display: flex;
	flex-direction: row ;
}
.inlineText h3{
	margin-right: 22px;
	margin-left: 12px;
	color: black;
}
.purpleContainer{
	background-color: #664499;
	margin-top: 10px;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	flex-direction: column ;
	width: 100% ;
	max-width: 1200px ;
	padding: 5px ;
	height: 100%;
	justify-content: space-evenly ;
	align-items: center;
	margin-top: 10px;	
	
}
.greyContainer{
	background-color: #2e293c;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	flex-direction: column ;
	padding: 5px 5px ;
	height: 100% ;
	align-items: center;
	color: #c6b76d;	
}
.BodyContainer{
/* all below have purpleContainer + */
}
.AccountBody{

}

.MyAlfHeader{
	height: 1000px;
	display: flex;
	flex-direction: column ;
	width: 100%;
	align-items: center;
}
.MyAlfContainer{
	background-color: #660099;
	border: 2px solid #c6b76d;
	border-radius: 25px;
	display: flex;
	flex-direction: column ;
	width: 100% ;
	max-width: 440px ;
	padding-top: 15px ;
	padding-left: 5px;
	min-height: 480px ;
	height: 300px;
	margin-left: 0px;
	margin-top: 10px;
	align-items: center;
}
.alienImage{
	width: 320px;
	background-size: cover;
}
 .alienImage img {
	width:100%;
	height:100%;
}
.StatsContainer{
	background-color: #2e293c;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	flex-direction: column ;
	width: 100% ;
	max-width: 250px ;
	padding: 10px 10px ;
	height: 100% ;
	align-items: center;
	color: #c6b76d;
}
.StatsModalWinnerContainer{
	margin-top:10px;
	background-color: #2e293c;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	flex-direction: column ;
	width: 100% ;
	max-width: 500px ;
	padding: 10px 10px ;
	height: 100% ;
	align-items: center;
	color: #c6b76d;
}
.StatsContainer p{
	line-height: 10px;
}
.MintContainer{
	display: flex;
    flex-direction: column ;
}

.TrophyContainer{
	background-color: #2e293c;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	width: 100% ;
	padding: 10px 10px ;
	align-items: center;
	color: #c6b76d;
	height: 620px;
	flex-direction: column ;
	justify-content: space-evenly;
}
.leaders{
	height: 300px;
	border: 2px solid #c6b76d;
	border-radius: 15px;
	width: 320px;
	overflow-y: scroll;
	padding-left: 5px;
}
.trophy{
	width: 80px;
}
.leaderboardEntries{
	padding : 0px 5px;		
	background-color: #664499;	
	width: 270px;
	margin-top:1px;
}
.headerContainerLeaderboard{
	display: flex;
	justify-content: center ;
	margin-top: 10px ;		
	overflow-y: scroll;
	height: 1260px;
}
.leaderBoardContainer{
	margin-top: 5px;
	width: 100%;
	padding-left: 20px;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	flex-direction: column;
	max-width: 1250px;
	padding: 20px 10px;
	align-items: center;
	color: #c6b76d;	
	height:680px;
}
.console-title{
	background-color: grey;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
	color: #00B5AD;
	font-family: 'Orbitron', sans serif ;
}

.scrollableXLeaderboard {
/*	width: 1020px; */
	width: 365px;
	overflow-x: scroll;
	background-color: #2e293c;
}
.leaderboardCard{
	height: 300px;
	width: 100%;	
	color: #ffffff;
	padding:10px;
	display: flex;
	flex-direction: row ;
}
.winnerContainer{
	 padding: 1px;
	padding-bottom: 10px;
	height: 260px;
	border-radius: 15px;
	color: #000;
	border: 2px solid #846E0A;
	width: 110px;
	background-color: #A9A7AF;
}
.paddingOverride{
	display: flex;
	flex-direction: row;	
	background-color: #664499;
}
.plotSquatContainer{
 	padding: 10px;
	display: flex;
	height: 120px;
	width: 100%;
	margin-top: 20px;
	margin-left: 1px;		
	}
.plotField{
	height: 400px;
	margin-top: 20px;
	width: 250px;
	display: none;
}
.plotFieldMobile{
	height: 450px;
	margin-top: 20px;
	width: 250px;
	display: inline;
}
.greyPlotContainer{
	background-color: #2e293c;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	flex-direction: column ;
	padding: 5px 5px ;
	height: 340px ;
	align-items: center;
	color: #c6b76d;	
}
.chooseLegContainer{
	padding: 10px;
	height: 350px;
	width: 600px;
	margin-top: 20px;
	margin-left: 5px;
	padding-left: 10px;
	padding-bottom: 20px;
}
.chooseEngineContainer{
	padding: 10px;
	height: 350px;
	margin-top: 20px;
	margin-left: 5px;
	padding-left: 10px;
	padding-bottom: 20px;
}
.legStrategy{
	background-color: #c6b76d;
	border: 2px solid #664499;
	border-radius: 5px;
	width: 120px ;
	padding: 2px;
	height: 30px ;
	align-items: center;
	text-align: center;
	color: black;	
	margin-left: 2px;
	font-weight: bold;
}
.helmBorder {
	height: 500px ;
	padding: 10px ;
	width: 348px;
	display: flex;
    flex-direction: column ;
	margin-left: 1px;
    border: 2px solid #c6b76d;
    border-radius: 35px;
    padding: 10px ;
    height: 400px ;
	color: #c6b76d;
}
.helmContainer {
    background-color: #664499;
    border: 2px solid #c6b76d;
    border-radius: 35px;
    display: flex;
    flex-direction: column ;
    width: 348px ;
    max-width: 900px ;
    padding: 10px  ;
	align-items: left;
    height: 530px ;   
	color: #111111;
}
.adminFieldContainer{
	padding: 10px  ;
	height: 760px;
	width:470px;
	width: 50% ;
}
.adminRaceContainer{
	padding: 10px;
	padding-bottom: 30px;
	height: 760px;
	width: 180px;
	width: 25% ;
}
.adminRaceContainer1{
	padding: 5px;
	padding-bottom: 10px;
	height: 500px;
	width: 180px;
	width: 25% ;
}
.userRaceContainer{
	padding: 10px;
	padding-bottom: 10px;
	height: 65%;
	width: 100% ;
}
.userRace1{
	height: 520px;
}
.userRace1a{
	padding: 10px;
	padding-bottom: 30px;
	height: 180px;
	width: 100%;
}
.userRace1b{
	padding: 5px;
	padding-bottom: 10px;
	height: 290px;
	width: 100% ;
}
.racePrestige{
	padding: 3px 10px 10px;
	height: 100px;
	width: 100%;
	background-color: #660099;
	margin-top: 10px;
}
.racePrestige p{
	line-height:3px;
}
.userRace3{
	margin-top: 10px;
	padding: 10px;
	padding-bottom: 20px;
	height: 250px;
	width: 60%;
}
.userRace4{
	margin-top: 10px;
	padding: 10px;
	padding-bottom: 10px;
	height: 100%;
	width: 50% ;
	margin-left: 10px;
	height: 250px;
}
.raceEntrants{
	padding: 0px 0px;
	border-radius: 10px;
	border: 1px solid #c6b76d;
	height: 40px;
	width: 130px;
	background-color: #337733;
	margin-top:1px;
}
.raceFinishers{
	padding: 0px 2px;
	border-radius: 10px;
	border: 1px solid #c6b76d;
	height: 40px;
	width: 140px;
	background-color: #3333ff;
	margin-top:1px;
	margin-left: 0px;
}
.scrollableHelm{
	overflow-y: scroll;
	height: auto;
}
		
.console-image{
    background-image: url(sky.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;	
}

Button {
	display: flex;
	justify-content: center;
	text-align: center; 
	align-items: center;
	line-height: 5px;
	padding-bottom: 1px;	
}
.trackUp:active {

}
.trackUp:visited{
	background-color: green;
}

.galaxy-image{
	width: 100%;
}
.galaxy-image{
	background-image: url(galaxy.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;	
}
.planet-image{
	background-image: url(planets.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;	
}
.flight-image {
	background-image: url(VJC.gif);
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;			
}	
.redBack {
	background-image: url(red.png);
	background-size: cover;
	background-attachment: fixed;	
}
.greenBack {
	background-image: url(green.png);
	background-size: cover;
	background-attachment: fixed;	
}
.blueBack {
	background-color: blue;
}
.goldBack {
	background-color: #fff200;
}
.animBack {
	background-image: url(VJCThin.gif);
	background-size: cover;
}
.impulse {
	background-image: url(starsMob.gif);
	background-size: 330px;
	z-index: 1;
}

.impulseScreenOff {
	background-image: url(screenOffline.jpg);
	background-size: cover;
	z-index: 1;	
}
.drift {
	background-image: url(driftMob.gif);
	background-size: cover;
	z-index: 1;
}
.warp {
	background-image: url(VJCMob.gif);
	background-size: 330px;
	z-index: 1;
}
.hyper {
	background-image: url(wormholeMob.gif);
	background-size: 330px;
	z-index: 1;
}
.meteors {
	background-image: url(meteorsMob.gif);
	background-size: 335px;
	z-index: 1;
}
.impulseShields {		
	background-image:url(shieldMob.png), url(starsMob.gif);
	background-size: 335px,335px;
	z-index: 1;
}
.meteorShields{
	background-image:url(shieldMob.png), url(meteorsMob.gif);
	background-size: 335px,335px;
	z-index: 1;
}
.skyStatic {	
	background-image: url(newSkyStatic.jpg);
	background-size: 330px,330px;
	z-index: 1;
}
.scrollBox {
	margin-top: 10px;
	height: 150px;
	width: 100%;
	overflow-y: scroll;
}
.scrollable {
	
	
}

.scrollableX {
	margin-left: 2px;
	width: 360px;
	overflow-x: scroll;
	background-color: #2e293c;
}

.scrollableY {
	height: 400px;
	overflow-y: scroll;
	border: 1px solid purple;
	padding: 5px;
}
.progressbar {
	width: 80%;
	margin: 25px auto;
	border: solid 1px #000;
}
.progressbar .inner {
	height: 15px;
	animation: progressbar-countdown;/* Placeholder, this will be updated using javascript */

	animation-duration: 40s;  /* We stop in the end */

	animation-iteration-count: 1; /* Stay on pause when the animation is finished finished */

	animation-fill-mode: forwards;/* We start paused, we start the animation using javascript */

	animation-play-state: paused;/* We want a linear animation, ease-out is standard */

	animation-timing-function: linear;
}
@keyframes progressbar-countdown {
	100% {
		width: 100%;
		background: #00f;
	}
	0% {
		width: 0%;
		background: #0f0;
	}
}
.testing{
	margin-top: -20px;
	margin-bottom: 10px;
}
.dashBoard{
	background-image: url(dashShortMob.png);
	width: 100%;
	margin-top: -80px;	
	z-index: 5;
	height: 98px;
	background-size: 330px;
	display: flex;
	flex-direction: row ;
}

.cokeRed {
	background-color: #ff0000;
    border: 4px solid #666666;
    border-radius: 12px;
    height: 26px ;
    width: 26px ;
    padding-top: 13px;
	padding-right: 16px;
	margin-top: 53px;
	margin-left: 1px;
	cursor: pointer;
	z-index: 3;
} 
.cokeText {
	align-items: left;
	color: #ffffff;
	margin-top: 60px;
	margin-left: 23px;
	z-index: 3;
}
.fuelGreen {
  background-color: #11ff11;
    border: 4px solid #666666;
    border-radius: 17px;
    height: 1px ;
    width: 26px ;
    padding-top: 17px;
	margin-top: 53px;
	margin-left: 176px;
	cursor: pointer;
	z-index: 3;
}
.fuelText {
	align-items: center;
	color: #ffffff;
	margin-top: 58px;
	margin-left: 2px;
	z-index: 3;
}
.fuelRed {
	background-color: #ff0000;
    border: 4px solid #666666;
    border-radius: 17px;
    height: 1px ;
    width: 26px ;
    padding-top: 17px;
	margin-top: 53px;
	margin-left: 176px;
	cursor: pointer;
	z-index: 3;
}
.buttonGreen {
	background-color: #21BA45;
    border: 2px solid #c6b76d;
    border-radius: 10px;
    width: 80px ;
    padding-top: 0px;
    height: 20px ;
	align-items: center;
	color: #ffffff;
	margin: auto;
	cursor: pointer;
}
.buttonRed {
	background-color: #db2828;
    border: 2px solid #c6b76d;
    border-radius: 10px;
    width: 80px ;
    padding-top: 0px;
    height: 20px ;
	align-items: center;
	color: #ffffff;
	margin: auto ;
	cursor: pointer;
}
.progressBarAmber{
	background-color: #ffff22;
    border-radius: 10px;
    width: 10% ;
    width: 40px ;
	height: 20px ;
	align-items: center;
	color: #000000;
	margin-right: -9px;
	padding-left: 10px;
	z-index: 3;
}
.progressBarGreen{  
	background-color: #22ff22;
	border-radius: 0  10px 10px 0 ;
	width: 10% ;
	width: 40px ;
	height: 20px ;
	align-items: center;
	color: #000000;
	margin-right: -9px;
	padding-left: 10px;
	z-index: 4;
}
.progressBarBorder{
	margin-top: 10px;
	background-color: #888888;
	width: 328px;
	z-index:6;
	border: 3px solid #c6b76d;
	border-radius: 10px;	
}
.progressBar{
	background-color: #888888;
	width: 325px;
	display: flex;
	flex-direction: row ;
	border: 2px solid #c6b76d;
	border-radius: 10px;
}
.finalFuelContainer{
	background-color: #664499;
	border: 2px solid #c6b76d;
	border-radius: 35px;
	display: flex;
	flex-direction: column ;
	width: 90% ;
	padding: 10px ;
	height: 220px ;
	justify-content: space-between ;
	color: #5588ff;
	margin-top: 10px ;
}	
.redText{
	color: #dd3300;
}
.blueText{
	color: blue;
}

/* iPad 810 x 1080 */
@media only screen and (min-width: 810px) and (max-width: 1150px){
	.scrollBox {
	margin-top: 10px;
	height: 250px;
	width: 100%;
	overflow-y: scroll;

}
	.tarImageL{
		float: left;
		width: 15%;
	}
	.tarImageR{
		float: right;
		width: 15%;
	}
		.scrollableX {
		width: 755px;
		overflow-x: scroll;
		background-color: #2e293c;
	}
	.scrollableXLeaderboard {
		width: 750px; 
		overflow-x: scroll;
		background-color: #2e293c;
	}
	.TrophyContainer{
		max-width: 780px;
		flex-direction: row ;
		justify-content: space-evenly;	
		height: 370px;	
	}
	
	.MyAlfContainer{	
		max-width: 600px ;	
		min-height: 600px ;
		height: 500px;
		align-items: center;
	}
	.alienImage{
		width: 450px;
		background-size: cover;
	}	
	
	.scrollableHelm{
		overflow-y: scroll;
		height: 900px;
		height: auto;
	}
	.plotField{
		height: 400px;
		margin-top: 20px;
		width: 250px;
		display: inline;
	}
	.plotFieldMobile{
		height: 450px;
		margin-top: 20px;
		width: 250px;
		display: none;
	}	
		.userRace3{
		margin-top: 10px;
		padding: 10px;
		padding-bottom: 20px;
		height: 220px;
		width: 60%;
	}
	.userRace4{
		margin-top: 10px;
		padding: 10px;
		padding-bottom: 10px;
		height: 100%;
		width: 50% ;
		margin-left: 10px;
		height: 220px;
	}
		.StatsContainer{
		width: 650px;
		margin-left: 10px;
		height: 270px;
		margin-top: 10px;
	}
}
/* size adjusted desktop */
@media only screen and (min-width: 1150px) and (max-width: 1500px){
			.scrollableX {
		width: 1050px;
		overflow-x: scroll;
		background-color: #2e293c;
	}
		.scrollBox {

	

}
	.scrollableXLeaderboard {
		width: 900px; 
		overflow-x: scroll;
	}
	.TrophyContainer{
		height: 350px;
		flex-direction: row ;
		justify-content: space-evenly;		
	}	
		.userRace3{
		margin-top: 10px;
		padding: 10px;
		padding-bottom: 20px;
		height: 460px;
		width: 60%;
	}
	.userRace4{
		margin-top: 10px;
		padding: 10px;
		padding-bottom: 10px;
		height: 100%;
		width: 50% ;
		margin-left: 10px;
		height: 460px;
	}
		.bothBoxes{
		display: flex;
		flex-direction: row;
		width: 1180px;
	}
		.MyAlfContainer{	
		max-width: 605px ;	
		min-height: 680px ;
		height: 520px;
		align-items: center;
	}
		.alienImage{
		width: 520px;
		background-size: cover;		
	}
		.MyAlfHeader{
		height: 700px;
		flex-direction: row;
	}
			.StatsContainer{
		width: 650px;
		margin-left: 10px;
		height: 680px;
		margin-top: 10px;
	}
		.plotField{
		height: 360px;
		margin-top: 20px;
		width: 250px;
		display: inline;
	}
	.plotFieldMobile{
		height: 450px;
		margin-top: 20px;
		width: 250px;
		display: none;		
	}
}
/* desktop screen */
@media only screen and (min-width: 1500px){
	.gemContainer{
		padding: 20px; 
		height: 720px ;
	}
	.frontMain{
		width: 1702px;
	}
	.frontContainer{
		background-image: url('tarFrontPageResizedAlpha.png'), url('b1873.jpg');
		background-size: 100%, 100%;
		height:1000px;	
		width:1600px;		
	}
	.modalPlotContainer{
		height: 720px;
	}
	.buttonContainer{
		width: 130px;
	}
	.enterRaceButtonContainer{
		width: 200px;
	}	
	.raceEntrants{
		width: 300px;
	}
	.flexColumn{
		display: flex;
		flex-direction: column ;
	}
	.userContainer{

	}
	.shortUser{
		height: 930px;	
	}
	.raceField{
		padding: 10px;
		border: 2px solid #c6b76d;
		border-radius: 35px;
		width: 540px;
	}
	.modalUser{
		height: 800px;
	}
	.tarImageL{
		float: left;
		width: 16%;
	}
	.tarImageR{
		float: right;
		width: 16%;
	}
	.bothBoxes{
		display: flex;
		flex-direction: row;
		width: 1180px;
	}
	h5{
		font-size: 16px;
	}
	h4{
		font-size: 16px;
	}
	h3{
		font-size: 18px;
	}
	.MyAlfHeader{
		height: 700px;
		flex-direction: row;
	}
	
	.MyAlfContainer{	
		max-width: 650px ;	
		min-height: 680px ;
		height: 520px;
		align-items: center;
	}
	
	.alienImage{
		width: 520px;
		background-size: cover;		
	}
	.alienImage img {
		width:100%;
		height:100%;
    }
	.scrollableX {
		width: 1160px;
		overflow-x: scroll;
		background-color: #2e293c;
	}
	.StatsContainer{
		width: 250px;
		margin-left: 10px;
		height: 680px;
		margin-top: 10px;
	}
	.plotField{
		height: 360px;
		margin-top: 20px;
		width: 250px;
		display: inline;
	}
	.plotFieldMobile{
		height: 450px;
		margin-top: 20px;
		width: 250px;
		display: none;		
	}
	.plotSquatContainer{
		padding: 10px;
		display: flex;
		height: 120px;
		width: 90%;
		margin: auto;
		margin-top: 20px;
				
		}
	.plotContainer{
		background-color: #2e293c;
		border: 2px solid #c6b76d;
		border-radius: 35px;
		width: 100% ;
		padding: 20px ;
		height: 350px ;  
		color: #c6b76d;
		margin-top: 20px;
		margin-left: 1px;
		line-height: 8px;
	}
	.chooseLegContainer{
		padding: 10px;
		height: 360px;
		width: 400px;
		margin-top: 20px;
		margin-left: 5px;
		padding-left: 10px;
		padding-bottom: 20px;
	}
	.chooseEngineContainer{
		padding: 10px;
		height: 360px;
		width: 400px;
		margin-top: 20px;
		margin-left: 5px;
		padding-left: 10px;
		padding-bottom: 20px;
	}
	
	.scrollableHelm{
		display: flex;
		flex-direction: row ;
		overflow-y: unset;
	}
	
	.helmBorder {
		padding: 10px ;
		width: 200px;
		display: flex;
		flex-direction: column ;
		margin: 10px;
		border: 2px solid #c6b76d;
		border-radius: 35px;
		padding: 10px ;
		height: 440px ;
		color: #c6b76d;
	}
	.helmContainer {
		width: 548px ;
		margin-top: 10px;
	}
	.inlineDesktop{
		display: flex;
		flex-direction: row ;		
	}

	.userRaceContainer{
		padding: 10px;
		padding-bottom: 30px;
		height: 300px;
		width: 250px;
		width: 50% ;
	}
	.userRace1{
		width: 400px;
	}
	.userRace1a{
		padding: 10px;
		padding-bottom: 30px;
		height: 230px;
		width: 100% ;
	}
	.userRace1b{
		padding: 5px;
		padding-bottom: 10px;
		height: 320px;
		margin-top: 10px;
		width: 100% ;
	}
	.userRace3{
		margin-top: 10px;
		padding: 10px;
		padding-bottom: 30px;
		height: 550px;
		width: 100% ;
	}
	.userRace4{
		margin-top: 10px;
		padding: 5px;
		padding-bottom: 10px;
		height: 550px;
		width: 80% ;
	}
	.scrollBox {
		margin-top: 10px;
		height: 550px;
		width: 100%;
		overflow-y: scroll;
	}
	.scrollable {


}
	.raceInline{
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;	
	}
	.TrophyContainer{
		max-width: 1200px;
		flex-direction: row ;
		justify-content: space-evenly;
		height: 350px;
	}
	.leaderBoardContainer{
		margin-top: 5px;
		height: 100%;
		padding-left: 20px;
		border: 2px solid #c6b76d;
		border-radius: 35px;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 20px 10px;
		align-items: center;
		color: #c6b76d;	
	}
	.scrollableXLeaderboard {
		width: 1130px; 
		overflow-x: scroll;
		background-color: #2e293c;
	}
	.leaderboardCard{
		height: 360px;
		width:1130px;
		color: #ffffff;
		padding:20px;
		display: flex;
		flex-direction: row;
	}
	.winnerContainer{
		height: 330px;
		width: 140px;
	}
	.paddingOverride{
		display: flex;
		flex-direction: row;	
		background-color: #664499;
	}

	.plotInline{
		display: flex;
		flex-direction: column ;
		justify-content: center;
		justify-content: space-evenly;	
	}
	
	.raceFinishers{
		width: 300px;
		height:35px;
	}

	.dashBoard{
		background-image: url(dashFixed.png);
		background-size: 530px;
		height: 110px;
		margin-top: -90px;
	}
	.skyStatic {
		background-image: url(newSkyStatic.jpg);
		background-repeat: no-repeat;
		background-size: 535px;
		z-index: 1;
	}
	.impulse {
		background-image: url(stars1.gif);
		background-size: cover;
		background-size: 535px;
		z-index: 1;
	}
	.drift {
		background-image: url(drift.gif);
		background-size: cover;
		background-size: 535px;		
		z-index: 1;
	}
	.warp {
		background-image: url(warp1.gif);
		background-size: cover;
		background-size: 535px;
		z-index: 1;
	}
	.hyper {
		background-image: url(wormhole3.gif);
		background-size: cover;
		background-size: 535px;
		z-index: 1;
	}
	.meteors {
		background-image: url(meteors2.gif);
		background-size: cover;
		background-size: 535px;
		z-index: 1;
	}
	.impulseShields {		
		background-image:url(shield.png), url(stars1.gif);
		background-size: 535px,535px;
		background-size: cover;
		z-index: 1;
	}
	.meteorShields{
		background-image:url(shield.png), url(meteors2.gif);
		background-size: 535px,535px;
		background-size: cover;
		z-index: 1;
	}	
	.progressBarAmber{
		width: 60px ;
		padding-left: 20px;
	}
	.progressBarGreen{  
		width: 60px ;
		padding-left: 20px;
	}
	.progressBarBorder{
		width: 528px;
	}
	.progressBar{
		width: 525px;
	}
	.cokeText {
		margin-top: 64px;
		margin-left: 93px;
	}	
	.cokeRed {
		margin-top: 63px;
		margin-left: 5px;
	} 
	.fuelGreen {
		margin-top: 64px;
		margin-left: 207px;
	}
	.fuelText {
		margin-top: 62px;
		margin-left: 2px;		
	}
	.fuelRed {
		margin-top: 63px;
		margin-left: 207px;
		color: red;
	}
	.buttonGreen {
		width: 100px ;
		padding-top: 0px;
		height: 25px ;
		align-items: center;
		color: #ffffff;
		margin: auto;
		margin-top: 10px;
		cursor: pointer;
	}
	.buttonRed {
		width: 100px ;
		padding-top: 0px;
		height: 25px ;
		align-items: center;
		color: #ffffff;
		margin: auto ;
		margin-top: 10px;
		cursor: pointer;
	}
	.leaders{
		height: 300px;
		border: 2px solid #c6b76d;
		border-radius: 35px;
		width: 220px;
		overflow-y: scroll;
		padding-left: 5px;
	}
	.leaderboardEntries{
		padding : 0px 5px;		
		background-color: #664499;	
		width: 180px;
		margin-top:1px;
	}
	.headerContainerLeaderboard{
		display: flex;
		justify-content: center ;
		margin-top: 10px ;			
		overflow-y: scroll;
		height: 960px;
	}

	.userGemContainer{
		height: 90%;
	}
}