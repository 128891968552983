.modal {
  position: fixed;
  top: 0;
  width:100%;
  height: 100%;
  background: rgba(0.0, 0.1, 0.5, 0.9);
}
h4 {
margin-top:1px;
margin-bottom: 1px;
}
.modal-main {
  position:fixed;
  background: grey;
  width: 70%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  text-align: center;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
.progress-bar
{
    position: relative;  
    display: inline-block;    
    margin: 6px 0;
}

.progress-bar:before
{
    content: '';   
    z-index: -1;    
    position: absolute;
    top: -6px;
    width: 16px;
    height: 28px;
}

.progress-bar:after
{
    content: '';    
    z-index: -1;    
    position: absolute;
    top: -6px;
    right: 0;
    width: 16px;
    height: 28px;
}

.progress-bar-glass
{
    display: inline-block;
    width: 400px;
    height: 16px;    
    z-index: 10;   
    border: 2px solid #000000;
    box-shadow: inset 0 0 0 6px #bcbcbc;
}

.progress-bar-fluid
{
    width: 60%;
    height: 100%;   
    background: #11ff11;    
    box-shadow: inset 0 1px 0 -1px #d3aa9a, 
        inset 0 -4px 0 -2px #4d494d, 
        inset 4px 0 0 -2px #4d494d, 
        inset -4px 0 0 -2px #4d494d;
}

.progress-bar-main
{
    position: relative;  
    display: inline-block;    
    margin: 0px 0;
}

.progress-bar-main:before
{
    content: '';   
    z-index: -1;    
    position: absolute;
    top: -6px;
    width: 16px;
    height: 28px;
}

.progress-bar-main:after
{
    content: '';    
    z-index: -1;    
    position: absolute;
    top: -6px;
    right: 0;
    width: 16px;
    height: 28px;
}

.progress-bar-glass-main
{
    display: inline-block;
    width: 860px;
    height: 26px;    
    z-index: 10;   
    border: 2px solid #000000;
    box-shadow: inset 0 0 0 12px #bcbcbc;
}

.progress-bar-fluid-main
{
    width: 60%;
    height: 100%;   
    background: #11ff11;    
    box-shadow: inset 0 1px 0 -1px #d3aa9a, 
        inset 0 -4px 0 -2px #4d494d, 
        inset 4px 0 0 -2px #4d494d, 
        inset -4px 0 0 -2px #4d494d;
}

.fuel-bar
{
    position: relative;  
    display: inline-block;    
    margin: 6px 0;
}

.fuel-bar:before
{
    content: '';   
    z-index: -1;    
    position: absolute;
    top: -6px;
    width: 16px;
    height: 28px;
}

.fuel-bar:after
{
    content: '';    
    z-index: -1;    
    position: absolute;
    top: -6px;
    right: 0;
    width: 16px;
    height: 28px;
}

.fuel-bar-glass
{
    display: inline-block;
    width: 120px;
    height: 16px;    
    z-index: 10;   
    border: 2px solid #000000;
    box-shadow: inset 0 0 0 6px #ce5050;
}

.fuel-bar-fluid
{
    width: 60%;
    height: 100%;   
    background: #11ff11;    
    box-shadow: inset 0 1px 0 -1px #d3aa9a, 
        inset 0 -4px 0 -2px #4d494d, 
        inset 4px 0 0 -2px #4d494d, 
        inset -4px 0 0 -2px #4d494d;
}

.anim-width-progress
{
    -webkit-animation:  anim50to100 50s infinite;
    animation: anim50to100 50s infinite;
}
.anim-width-fuel
{
    -webkit-animation:  anim100to1 50s infinite;
    animation: anim100to1 50s infinite;
}
@-webkit-keyframes anim50to100 {
    0%   { width: 1%; }  
    100% { width: 100%; }
}
@keyframes anim50to100 {
    0%   { width: 1%; } 
    100% { width: 100%; }
}
@-webkit-keyframes anim100to1 {
    0%   { width: 100%; }  
    100% { width: 10%; }
}
@keyframes anim100to1 {
    0%   { width: 100%; } 
    100% { width: 10%; }
}
html,
body
{
	padding: 0;
	margin: 0;
}
html
{
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}
body
{
	min-width: 100%;
	min-height: 100%;
}

*,
*:before,
*:after 
{
	box-sizing: inherit;
}

